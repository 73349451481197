<template>
  <a-row :gutter="10">
    <a-col :span="4">
      <Template @success="setCurrItem"/>
    </a-col>
    <a-col :span="20">
      <List ref="list"/>
    </a-col>
  </a-row>
</template>
<script>
import Template from './template/Index'
import List from './list/Index'
export default {
  components: {
    Template,
    List
  },
  data() {
    return {}
  },
  methods: {
    setCurrItem(item){
      this.$refs.list.setData(item)
    }
  },
}

</script>
<style scoped lang="less">

</style>
