<template>
  <a-card :bordered="false">
    <div class="con">
      <div class="head">
        <div class="card-title">合同列表</div>
        <a-button v-action:scht style="margin-bottom: 12px" type="primary" icon="plus" @click="handleAdd">上传合同</a-button>
        <a-input-search
            allowClear
            v-model="queryParam.keyword"
            placeholder="请输入合同名称"
            @search="onSearch"
        />
      </div>
      <div style="margin: 5px 0">选择一项合同：</div>
      <div class="main">
        <a-spin :spinning="confirmLoading">
          <div class="list" v-if="list.length">
            <div @click="setActionVehicle(item)"
                 :class="['list-item',actionVehicle.contract_id==item.contract_id?'action':'']" v-for="item in list">
              <div>{{ item.contract_name }}</div>
              <div>
                <a-tooltip placement="top" v-action:ylht>
                  <template slot="title">
                    <span>预览合同</span>
                  </template>
                  <a-icon type="eye" @click="handlePreview(item.contract_file.external_url)" style="margin-right: 5px"/>
                </a-tooltip>

                <a-tooltip placement="top" v-action:bjht>
                  <template slot="title">
                    <span>编辑</span>
                  </template>
                  <a-icon type="edit" @click="handleEdit(item)" style="margin-right: 5px"/>
                </a-tooltip>

                <a-tooltip placement="top" v-action:scht>
                  <template slot="title">
                    <span>删除</span>
                  </template>
                  <a-popconfirm
                      title="确认删除当前合同？"
                      ok-text="确定"
                      cancel-text="取消"
                      @confirm="handleDelete(item)"
                  >
                    <a-icon type="delete"/>
                  </a-popconfirm>
                </a-tooltip>
              </div>
            </div>
          </div>
          <a-empty v-else/>
        </a-spin>
      </div>
    </div>
    <SaveForm ref="SaveForm" @handleSubmit="getList"/>
  </a-card>
</template>
<script>
import * as Api from '@/api/enterprise/contract'
import SaveForm from "./modules/SaveForm";

export default {
  components: {SaveForm},
  data() {
    return {
      queryParam: {
        keyword: ''
      },
      list: [],
      actionVehicle: {},
      confirmLoading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /**
     * 新增记录
     */
    handleAdd() {
      this.$refs.SaveForm.add()
    },

    /**
     * 编辑记录
     */
    handleEdit(item){
      this.$refs.SaveForm.edit(item)
    },

    handleDelete(item){
      Api.deleted({contract_id: item['contract_id']})
          .then((result) => {
            this.$message.success(result.message, 1.5)
            this.getList()
          })
    },

    handlePreview(url) {
      window.open('https://www.pfile.com.cn/api/profile/onlinePreview?url=' + url)
    },

    async getList() {
      this.confirmLoading = true
      const {data: {list}} = await Api.all(this.queryParam)
      this.list = list
      this.confirmLoading = false
    },
    onSearch(e) {
      this.getList()
    },
    setActionVehicle(item) {
      this.actionVehicle = item
      this.$emit('success', this.actionVehicle)
    }
  },
}

</script>
<style scoped lang="less">
.con {
  height: calc(100vh - 140px);
  background: #FFFFFF;
  flex-direction: column;
  display: flex;
}

.head {
  flex-grow: 1;
}

.main {
  height: 100%;
  flex-grow: 1;
  overflow: scroll;
}

.list {
  border: 1px solid #e8e8e8;

  .list-item {
    font-weight: bold;
    border-bottom: 1px solid #e8e8e8;
    padding: 8px;
    cursor: pointer;
    transition: all .3s;
    display: flex;
    justify-content: space-between;

    &:hover {
      background: #f4f4f4;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .action {
    background: #378DDB !important;
    color: #FFFFFF;
  }
}
</style>
