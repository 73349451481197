<template>
  <a-modal
      :title="title"
      :width="520"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="合同名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              placeholder="请输入合同名称"
              v-decorator="['contract_name', {rules: [{required: true, message: '请输入费用项目名称'}]}]"
          />
        </a-form-item>
        <a-form-item label="合同文件" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <template v-if="mode=='add'">
            <UploadFile
                accept=".doc,.docx"
                v-decorator="['contract_file_id', {rules: [{required: true, message: '请上传合同文件'}]}]"
            />
            需将签名标识<span style="font-weight: bold">${sign}</span>放置签名处，<a target="_blank" href="https://zcjh.iyexi.com/assets/1716955205458.jpg">查看示例</a>
          </template>
          <template v-if="mode=='edit'">
            <a style="font-weight: bold">{{ record.contract_file ? record.contract_file.file_name : '-' }}</a>
          </template>
        </a-form-item>
        <!--        <a-form-item label="启用状态" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
        <!--          <a-radio-group-->
        <!--              v-decorator="['status', { initialValue: 0,rules: [{ required: true }] }]"-->
        <!--          >-->
        <!--            <a-radio :value="0">启用</a-radio>-->
        <!--            <a-radio :value="1">停用</a-radio>-->
        <!--          </a-radio-group>-->
        <!--        </a-form-item>-->
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import * as Api from '@/api/enterprise/contract'
import _ from "lodash";
import UploadFile from "@/components/UploadFile/UploadFile.vue";
export default {
  components: {UploadFile},
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: ""
    }
  },
  methods: {

    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '新增'

      this.visible = true
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑'
      this.visible = true

      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['contract_name', 'contract_file_id'])
        setFieldsValue(data)
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({contract_id: this.record['contract_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
